<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard body-wrapper>
            <vue-typeahead-bootstrap
              :data="markers"
              backgroundVariant="list-group-item list-group-item-action"
              @hit="clickOnStudent"
              :serializer="item => item['student_id']"
              placeholder="ค้นหาโดยใช้รหัสนักศึกษา"
            >
              <template #prepend>
                <CButton  class="btn btn-secondary">
                  <i class="fas fa-search-location"></i> ค้นหาผู้สอบ
                </CButton>
              </template>
            </vue-typeahead-bootstrap>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12">
        <div style="height: 700px; width: 100%">
          <l-map :zoom="zoom" :center="center" :options="mapOptions" style="height: 100%" @update:center="centerUpdate" @update:zoom="zoomUpdate">
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-marker v-for="(marker, index) in markers" :key="index" :lat-lng="marker.position" :icon="getIcon(marker,index)">
              <l-tooltip :options="{ permanent: true, interactive: true }">
                {{ marker["student_id"] }}
              </l-tooltip>
              <l-popup>
                <div @click="innerClick(index)">
                  {{ marker["fullname"] }}
                </div>
              </l-popup>
              <!-- <l-popup v-if="showImHere === marker['student_id']">I'm here</l-popup> -->
            </l-marker>
          </l-map>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet"
import L from 'leaflet'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import examManangerApi from "@/services/apiServices"

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    VueTypeaheadBootstrap
    // showParagraph: true,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 14,
      center: { lat: 18.80127937334272, lng: 98.96342223946948 },
      withTooltip: { lat: 18.80127937334272, lng: 98.96342223946948 },
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      eventid: "",
      markers: [],
      markers_reset: [],
      searchList: [],
      showImHere: "621410062",
    }
  },
  async created() {
    this.eventid = this.$route.params.eventid
    await this.getLocationStudent()
  },
  mounted() {
    this.geolocate()
  },
  computed: {

  },
  methods: {
    zoomUpdate(zoom) {
      this.zoom = zoom
    },
    centerUpdate(center) {
      this.center = center
    },
    innerClick() {
      alert("Click!")
    },
    async getLocationStudent() {
      if (this.center) {
        let locationList = await examManangerApi.getLocationOfEvent(this.eventid)
        console.log("getLocationStudent:",locationList)
        for (let location of locationList["data"]) {
          let tempMarker = JSON.parse("{}")
          tempMarker["lat"] = location["latitude"]
          tempMarker["lng"] = location["longitude"]
          let tempLocation = JSON.parse("{}")
          tempLocation["position"] = tempMarker
          tempLocation["fullname"] = location["fullname"]
          tempLocation["student_id"] = location["student_id"]
          // tempLocation["color"] = '#c11a1a'
          // tempLocation["strokeColor"] = '#d73534'
          // tempLocation["circleColor"] = '#590000'
          this.markers.push(tempLocation)
          this.searchList.push(tempLocation["student_id"])
        }
        console.log("this.location ", this.markers)
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      })
    },
    async clickOnStudent(val) {
      console.log(val)
      let position = -1
      for(let i=0;i<this.markers.length;i++){
        if(val["student_id"] === this.markers[i]["student_id"]){
          position = i
          break
        }
      }
      if(position > -1){
        setTimeout(() => (this.center = val["position"]), 500)
        // this.showImhere = val["student_id"]
        this.zoom = 20
        // this.markers[position]["color"] = '#c11a1a'
        // this.markers[position]["strokeColor"] = '#d73534'
        // this.markers[position]["circleColor"] = '#590000'
      }
    },
    getIcon(item,index) {
      // console.log(index)
      if(item["circleColor"]){
        return L.divIcon({
          className: "my-custom-pin",
          html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="60" width="40">
            <g transform="translate(-814.59595,-274.38623)">
              <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
                <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="fill:${item.color};stroke:${item.strokeColor};"/>
                <circle r="3.0355" cy="288.25278" cx="823.03064" id="path3049" style="display:inline;fill:${item.circleColor};"/>
              </g>
            </g>
          </svg>`
        })
      }else{
        return L.divIcon.Default
      }
    }
  },
}
</script>
